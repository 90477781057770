import { Countries } from '@/types/Countries';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const experimentNames = {
  homepageStorytelling: 'homepageStorrytelling',
  signUpFeeReduction: 'signUpFeeReduction',
  priceBreakdownRetest: 'priceBreakdownRetest',
  framerHomepageAA: 'framerHomepageAA',
  basketConfigureFlow: 'basketConfigureFlow',
  cityFinderFramer: 'cityFinderFramer',
} as const;

export type ExperimentName = keyof typeof experimentNames;
export type ExperimentCountry = Countries | 'ALL';
